import React, { Component } from "react";
import "./ProductListing.scss";
import Header from "../../components/Header/Header";
import ProductsCard from "../../components/ProductsCard/ProductsCard";

import * as constants from '../../constants';

let productObjectArray;
class ProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsData: ''
    };
  }

  componentDidMount() {


    this.getProductsData();

  }

  getProductsData = () => {
    const headers = { 'Content-Type': 'application/json' }

    fetch(constants.BASE_PRODUCTS_API, {

      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.ok) {

          return res.json();

        } else {

          console.log('res: ', res);

          throw Error(res.statusText);
        }
      })
      .then(json => {


        productObjectArray = json.slice(0);


        let productObject = [];
        productObject.push(json);
        this.setState({
          productsData: json
        })

      })
      .catch(error => console.error(error));

  }

  renderProductCards = () => {

    let result = [];

    console.log('this.state.productsData: ', this.state.productsData)
    if (this.state.productsData.length > 0) {

      this.state.productsData.map((el, index) =>
        result.push(
          <div className="ind-product-card"> <ProductsCard onClick = {this} key={index} productData={this.state.productsData[index]} />

          </div>
          )
      )
    }

    else {
      result.push(
        <div className="empty-page-main-container">

          {/* <EmptyPageCard emptyPageCardData = { emptyCardData } /> */}

        </div>
      )

    }
    return result;
  }

  render() {
    return (
      <div className="productListing-main-container">
        <Header />

        <section
          id="our-productListing-tequip"
          className="section section--large section--centered section--in-view products-section"
        >
          <div className="our-productListing-tequip-container">
            <span className="our-productListing-tequip-text fw-bold">
              OUR PRODUCTS
            </span>
          </div>
          <div className="our_productListing_tequip_parent_section__container">
          </div>
          <div className="city_square_app_container">
            <span >{this.state.productsData != undefined && this.state.productsData != null ?
              this.state.productsData[0] != undefined ? this.renderProductCards() : ''
              : ''}</span>
          </div>


        </section>


        <footer className="footer-section-productListing">
          <span>
            Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd. <br />
            All rights reserved
          </span>
        </footer>
      </div>
    );
  }
}

export default ProductListing;
