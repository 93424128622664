import React, { Component } from "react";
import "./JobOpeningCard.scss";
class JobOpeningCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individualjobOpeningsDataPageStatus: false,
      loaded: false,
      logo: "",
    };
  }

  componentDidMount = () => {};

  renderAvailableJobOpeningCards = () => {
    let result = [];
    if (
      this.props.jobOpeningsData != undefined ||
      this.props.jobOpeningsData != null
    ) {
      result.push(
        <div className="job-opening-card-main-container">
          <div className="support-team-container">
            <div className="job-card-list">
              <div className="job-opening-individual-container">
                <div className="job-opening-individual-info ml-3">
                  <div className="job-title">
                    {this.props.jobOpeningsData.title != null &&
                    this.props.jobOpeningsData.title != undefined
                      ? this.props.jobOpeningsData.title
                      : "Apply now"}
                  </div>
                  <div className="job-details-container">
                    <div className="job-details-desc">
                      {this.props.jobOpeningsData.role != null &&
                      this.props.jobOpeningsData.role != undefined
                        ? this.props.jobOpeningsData.role
                        : "PART TIME"}{" "}
                      ,
                    </div>
                    <div className="job-details-desc">
                      {this.props.jobOpeningsData.details != null &&
                      this.props.jobOpeningsData.details != undefined
                        ? this.props.jobOpeningsData.details
                        : "BUSINESS"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      result.push(<div className="job-opening-card-main-container"></div>);
    }

    return result;
  };

  openURL = (url) => {
    if (url != null && url != "") {
      window.open(url, "_black");
    }
  };

  render() {
    return (
      <div className="available-job-opening-card shadow">
        {this.renderAvailableJobOpeningCards()}
      </div>
    );
  }
}

export default JobOpeningCard;
