import React, { Component } from "react";

import "./NotFound.scss";
import Header from "../../components/Header/Header";
import notFoundPageIllstn from "../../../assets/not_found_image.png";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="not-found-main-container">
        <Header />

        <section
          id="not-found-tequip"
          className="section section--large section--centered section--in-view"
        >
          <div className="not-found-tequip-text-container">
            <img className="not-found-us-illstn" src={notFoundPageIllstn} />

            <span className="not-found-tequip-text-desc">
              <b>Oops!</b> <br /> The page you requested could not be found!{" "}
              <br />
              Is there any chance you were looking for one of these? <br />
              <div>
                <span>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.prashanth.guru.citysquareapp"
                    className="not-found-link-options"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    City Square app
                  </a>
                </span>
                <br />
                <span>
                  <a
                    href="https://www.instagram.com/citysquare.tech/"
                    className="not-found-link-options"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    City Square Instagram Page
                  </a>
                </span>
                <br />
                <span>
                  <a
                    href="https://in.linkedin.com/company/tequip-software-solutions?trk=public_profile_topcard-current-company"
                    className="not-found-link-options"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TeQuip LinkedIn Page
                  </a>
                </span>
                <br />
              </div>
            </span>

            <a
              href="/"
              className="take-me-home-btn btn-primary"
              rel="noopener noreferrer"
            >
              Nah! Take me home
            </a>
          </div>
          <footer className="footer-section-not-found">
            {" "}
            <span>
              Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd. <br />{" "}
              All rights reserved
            </span>{" "}
          </footer>
        </section>
      </div>
    );
  }
}

export default NotFound;
