import React, { Component } from "react";
import "./OurServicesCard.scss";

import designImage from "../../../assets/homePageImages/design-icon.png";
import webDevImage from "../../../assets/homePageImages/web-dev-icon.png";
import appDevImage from "../../../assets/homePageImages/app-dev-icon.png";
import productDevImage from "../../../assets/homePageImages/product-dev-icon.png";

class OurServicesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="our-services-card-container">
        <li className="grid__item feature feature--yellow feature--1">
          <span className="feature__label">
            {this.props.ourServicesDataObj.serviceCount}
          </span>
          <img
            src={
              this.props.ourServicesDataObj.serviceTitle == "Web Development"
                ? webDevImage
                : this.props.ourServicesDataObj.serviceTitle ==
                  "App Development"
                ? appDevImage
                : this.props.ourServicesDataObj.serviceTitle ==
                  "Product Development"
                ? productDevImage
                : this.props.ourServicesDataObj.serviceTitle ==
                  "Design and Branding"
                ? designImage
                : ""
            }
            className="our-services-icon icon--feature feature__icon"
          ></img>
          <h2 className="gamma | feature__heading">
            {this.props.ourServicesDataObj.serviceTitle}
          </h2>
          <div className="feature__text">
            {this.props.ourServicesDataObj.serviceDescription}
          </div>
        </li>
      </div>
    );
  }
}

export default OurServicesCard;
