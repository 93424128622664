import React, { Component } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.css";

import Home from "./app/screens/Home/Home";
import InTheNews from "./app/screens/InTheNews/InTheNews";
import About from "./app/screens/About/About";
import Products from "./app/screens/Products/Products";
import Contact from "./app/screens/Contact/Contact";
import NotFound from "./app/screens/NotFound/NotFound";
import Careers from "./app/screens/Careers/Careers";
import ProductListing from "./app/screens/ProductListing/ProductListing";
import CinemaholicProduct from "./app/screens/CinemaholicProduct/CinemaholicProduct";
import SuperChefProduct from "./app/screens/SuperChefProduct/SuperChefProduct";
import ThisDayThatYearProduct from "./app/screens/ThisDayThatYearProduct/ThisDayThatYearProduct";

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/in-the-news" component={InTheNews} />
          <Route exact path="/about" component={About} />
          <Route exact path="/citysquareapp" component={Products} />
          <Route exact path="/cinemaholicapp" component={CinemaholicProduct} />
          <Route exact path="/superchef" component={SuperChefProduct} />
          <Route exact path="/thisdaythatyearapp" component={ThisDayThatYearProduct} />
          <Route exact path="/products" component={ProductListing} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default AppRouter;
