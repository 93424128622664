import React, { Component } from "react";

import "./Products.scss";
import Header from "../../components/Header/Header";
import productsPageIllstn from "../../../assets//productsImages/products_illstn.png";
import citySquareIllstn from "../../../assets//productsImages/city_square_mobile_mockup.png";
import googlePlayIcon from "../../../assets/google-play-badge.png";
import followUsOnInstagram from "../../../assets/follow_us_instagram.jpg";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="products-main-container">
        <Header />

        <section
          id="our-products-tequip"
          className="section section--large section--centered section--in-view"
        >
          {/* <div className="our-products-tequip-container">
            <span className="our-products-tequip-text fw-bold">
              OUR PRODUCTS
            </span>
          </div> */}
          {/* <div className="our_products_tequip_parent_section__container">
            <img className="products_illstn" src={productsPageIllstn} />
          </div> */}
          <div className="city_square_app_container">
            <img className="city_square_illstn" src={citySquareIllstn} />
            <div className="city_square_app_desc_container">
              <span className="city_square_app_desc_title fw-bold">
                CITY SQUARE APP
              </span>
              <span className="city_square_app_desc_text fw-bold">
                People travel a lot every day to meet-up friends, family, or
                even for business purposes. They invest a lot of time, energy
                and money on the commute during the meetup. Arguments, fights
                and cancellation became an inevitable part of the planning due
                to this commotion. <br />
                This motivated us into creating City Square - an Android App.
              </span>
              <a
                href="https://play.google.com/store/apps/details?id=com.prashanth.guru.citysquareapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="google_play_badge" src={googlePlayIcon} />
              </a>
            </div>
          </div>

          <div className="city-square-problemstatement-container">
            <span className="city_square_app_desc_title fw-bold">
              MORE ABOUT CITY SQUARE APP
            </span>
            <span className="city_square_app_desc_text fw-bold">
              City Square is the look we took before leaping into the startup
              industry. Our Mobile App is the <b>First Indian product</b> that
              finds Equidistant and Central Location among any number of given
              locations. <br /> <br /> This app was the result of struggles
              abstracted in most of the meetups; From Cancelling the gather due
              to the spot being far or it being closer to only certain set of
              people or having to go back home completely drained after the long
              commute. <br />
              <br /> Our City Square App shows the central location between two
              or more locations so that none among the group may have to travel
              a bit more than the other. It also offers restaurant
              recommendations near the aforementioned Central Location.
            </span>
          </div>
          <div className="city_square_stats_container">
            <div className="city_square_stats_sub_container">
              <div className="city_square_downloads_container">
                <span className="city_square_stat_heading fw-bold">3.5k+</span>
                <span className="city_square_stat_content fw-bold">
                  Downloads
                </span>
              </div>
              <div className="city_square_downloads_container">
                <span className="city_square_stat_heading fw-bold">4.9</span>
                <span className="city_square_stat_content fw-bold">Rating</span>
              </div>
              <div className="city_square_downloads_container">
                <span className="city_square_stat_heading fw-bold">800+</span>
                <span className="city_square_stat_content fw-bold">
                  Reviews
                </span>
              </div>
            </div>

            <iframe
              className="city_square_video"
              src="https://www.youtube.com/embed/N8HtO0fh4z8"
            />
          </div>
        </section>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/citysquare.tech/"
        >
          <div className="follow-us-container">
            <img className="follow-us-image" src={followUsOnInstagram} />
            <span className="insta_username fw-bold">@citysquare.tech</span>
          </div>
        </a>

        <footer className="footer-section-products">
          <span>
            Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd. <br />
            All rights reserved
          </span>
        </footer>
      </div>
    );
  }
}

export default Products;
