import React, { Component } from "react";
import Header from "../../components/Header/Header";
import "./Home.scss";

import aboutBackgroundImage from "../../../assets/homePageImages/about_background.png";
import whyTequipBackgroundImage from "../../../assets/homePageImages/why_tequip_logo.png";
import OurServicesCard from "../../components/OurServicesCard/OurServicesCard";
import Splash from "../Splash/Splash";

import Typed from "react-typed";

import linkedInImage from "../../../assets/socialMedia/linkedin.png";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splashScreenLoaded: undefined,
      ourServicesData: [],
    };
  }

  componentDidMount() {
    let sampleOurServicesData = [];
    sampleOurServicesData.push(
      {
        serviceTitle: "Web Development",
        serviceDescription:
          "User Interface is like a joke. If you have to explain it, it's not that good. That being said, we aim to be the funniest people you're going to meet. We take your vision into our visualization techniques and give the visuals back to you in the form of Web Applications.",
      },
      {
        serviceTitle: "App Development",
        serviceDescription:
          "We build mobile apps that are loved by users. Our aim is to create an amazing app user experience to spur your brand’s growth.",
      },

      {
        serviceTitle: "Product Development",
        serviceDescription:
          "The best way to predict the future is to create it. Join hands with us to build cool products to scale your business!",
      },
      {
        serviceTitle: "Design and Branding",
        serviceDescription:
          "Every great design begins with an even better story. Begin your success story with our qualified and designated designers.",
      }
    );

    setTimeout(() => {
      this.setState({
        splashScreenLoaded: true,
        ourServicesData: sampleOurServicesData,
      });
    }, 2000);
  }

  render() {
    return (
      <div className="tequip-home-main-container">
        {!this.state.splashScreenLoaded ? (
          <Splash />
        ) : (
          <div className="home-header-container">
            <Header />

            <div className="home-background-image-container">
              <img
                className="home-background-image"
                src={aboutBackgroundImage}
              />
              <div className="home-page-text-container">
                {/* <img className="home-page-tequip-logo" src = { tequipLogo } /> */}
                <span className="tequip-slogan-title fw-bold">
                  <Typed
                    strings={["E-Quip yourself with Technology"]}
                    typeSpeed={40}
                  />
                </span>
                <span className="tequip-slogan-description fw-bold">
                  This is your one stop solution for all your Software and
                  Technological Needs.
                </span>
                <span className="tequip-slogan-description fw-bold">
                  You provide your requirements, we provide our Innovative and
                  Creative focus on it.
                </span>

                <div className="get-started-text-container">
                  <a href="#our-services" className="btn-primary">
                    Get Started
                  </a>
                </div>
              </div>
            </div>

            <section
              id="why-tequip"
              className="section section--large section--centered section--in-view"
            >
              <div className="why-tequip-container">
                <span className="why-tequip-text fw-bold">WHY TEQUIP</span>
              </div>
              <div className="why_tequip_parent_section__container">
                <span className="why-tequip-description">
                  Wouldn't you want a hassle free client-server relationship?
                  That's exactly what we're ready to give. Our skill-set extends
                  across many overlapping areas of business expertise and we
                  thrive for your success. <br />
                  <br />
                  We make sure to provide you an easy and frictionless
                  experience with our unique approach and flexible pricing.
                </span>
              </div>
              <div className="why_tequip_image_container">
                <img src={whyTequipBackgroundImage} />
              </div>
            </section>

            <section
              id="our-services"
              className="section section--large section--centered section--in-view"
            >
              <div className="our-services-container">
                <span className="our-services-text fw-bold">OUR SERVICES</span>
              </div>
              <div className="our_services_parent_section__container">
                <div className="our-services-section__content our-services-section__content--animated">
                  {this.state.ourServicesData.map((el, index) => (
                    <OurServicesCard
                      serviceCount={index}
                      key={index}
                      ourServicesDataObj={this.state.ourServicesData[index]}
                      id={index}
                    />
                  ))}
                </div>
              </div>

              <div className="home-tequip-support-social-media-container">
                <span className="home-follow-us-text">
                  {" "}
                  Follow us on LinkedIn
                </span>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://in.linkedin.com/company/tequip-software-solutions?trk=public_profile_topcard-current-company"
                >
                  <img
                    className="home_page_linkedin_image"
                    src={linkedInImage}
                  />
                </a>
              </div>

              <footer className="footer-section">
                {" "}
                <span>
                  Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd.{" "}
                  <br /> All rights reserved
                </span>{" "}
              </footer>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default Home;
