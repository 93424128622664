import React, { Component } from "react";

import "./Careers.scss";
import Header from "../../components/Header/Header";
import careersPageIllstn from "../../../assets/careersPageImages/careers_illstn.jpg";
import linkedInImage from "../../../assets/socialMedia/linkedin.png";
import JobOpeningCard from "../../components/JobOpeningCard/JobOpeningCard";

class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobOpeningsData: [],
    };
  }

  componentDidMount() {
    let sampleJobOpeningsData = [];
    sampleJobOpeningsData.push(
      {
        title: "Business Development Executive",
        role: "INTERN",
        details: "BUSINESS",
      },
      {
        title: "Customer Success Executive",
        role: "INTERN",
        details: "BUSINESS",
      },
      {
        title: "Full-Stack Developer",
        role: "PART TIME",
        details: "ENGINEERING",
      },
      {
        title: "Android App Developer",
        role: "PART TIME",
        details: "ENGINEERING",
      },
      {
        title: "iOS App Developer",
        role: "PART TIME",
        details: "ENGINEERING",
      },
      {
        title: "Web Developer",
        role: "PART TIME",
        details: "ENGINEERING",
      },
      {
        title: "Social Media Strategist",
        role: "PART TIME",
        details: "MARKETING",
      },
      {
        title: "Graphic Designer",
        role: "PART TIME",
        details: "DESIGN",
      }
    );
    this.setState({
      jobOpeningsData: sampleJobOpeningsData,
    });
  }

  render() {
    return (
      <div className="careers-main-container">
        <Header />

        <section
          id="careers-tequip"
          className="section section--large section--centered section--in-view"
        >
          <div className="careers-tequip-container">
            <span className="careers-tequip-text fw-bold">Join the team</span>
          </div>
          <div className="careers_tequip_text_container">
            <img className="careers-illstn" src={careersPageIllstn} />

            <span className="careers-tequip-text fw-bold">
              Together @ TeQuip
            </span>
            <span className="careers-tequip-title fw-bold">Open Positions</span>

            <div className="careers-card-container">
              {this.state.jobOpeningsData.map((el, index) => (
                <JobOpeningCard
                  key={el}
                  jobOpeningsData={this.state.jobOpeningsData[index]}
                  id={index}
                />
              ))}
            </div>
            <div className="careers-apply-now-container">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://forms.gle/JqgWQL1qfZcz7NXD7"
                className="btn-primary"
              >
                Apply Now
              </a>
            </div>

            {/* <iframe
              className="careers_video"
              src="https://youtube.com/embed/NV0AuQzICeU"
            /> */}
          </div>

          <footer className="footer-section-products">
            {" "}
            <span>
              Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd. <br />{" "}
              All rights reserved
            </span>{" "}
          </footer>
        </section>
      </div>
    );
  }
}

export default Careers;
