import React, { Component } from "react";
import Header from "../../components/Header/Header";

import "./About.scss";
import founderLogo from "../../../assets/aboutPageImages/founder_pic2.JPG";
// import founderLogo from "../../../assets/aboutPageImages/founder_pic.png";
// import shivaniPic from "../../../assets/aboutPageImages/shivani_pic.jpg";
// import rohitthpPrakashPic from "../../../assets/aboutPageImages/rohitthPrakash_pic.jpg";
// import yazirPic from "../../../assets/aboutPageImages/yazir_pic.jpg";
// import sangeethaPic from "../../../assets/aboutPageImages/sangeetha_pic2.jpg";

// import linkedInImage from "../../../assets/socialMedia/linkedin.png";
// import twitterImage from "../../../assets/socialMedia/twitter.png";
// import fbImage from "../../../assets/socialMedia/facebook.png";

import valuesImage1 from "../../../assets/aboutPageImages/Values/values1.png";
import valuesImage2 from "../../../assets/aboutPageImages/Values/values2.png";
import valuesImage3 from "../../../assets/aboutPageImages/Values/values3.png";
import valuesImage4 from "../../../assets/aboutPageImages/Values/values4.png";
import Typed from "react-typed";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <Header />
        <section
          id="about-tequip"
          className="section section--large section--centered section--in-view"
        >
          <div className="about-tequip-container">
            <span className="about-tequip-text fw-bold">OUR STORY</span>
          </div>
          <div className="about_tequip_parent_section__container">
            <span className="about-tequip-description fw-bold">
              <br />
              <span className="necessity-quote">
                <Typed
                  strings={["Necessity is the mother of all inventions"]}
                  typeSpeed={40}
                />
                <br />
              </span>{" "}
              <br /> We have showed the world, the first Indian implementation of the
              concept of equidistant locations through our 1st product-
              <a
                target="_blank"
                className="about_city_square_text"
                href="https://play.google.com/store/apps/details?id=com.prashanth.guru.citysquareapp"
              >
                City Square app
              </a>
              <br />
              The B2B2C SAAS app was single-handedly developed by our founder.
              <br />
              <br />
              We hope to bridge the gap between amazing products and end users.
              Our aim is to develop products and tech solutions that are
              futuristic, and stay for years to come. <br />
              <br />
              We start each day opening our minds to more learning opportunities
              and to show everyone what we learnt. We hope you give us a chance
              for us to wear different hats as your one stop solution for all
              your tech needs.
              <br />
              <br />
            </span>
          </div>
          <div className="about_tequip_founder_details_container">
            {/* <img className="about_tequip_founder_image" src={founderLogo} /> */}
            <span className="about-tequip-founder-title fw-bold">
              Gurunath Neelamani (gurunath.neelamani@gmail.com)
            </span>
            <span className="about-tequip-founder-designation fw-bold">
              Director 
            </span>

            <br /> <br />
            
            <span className="about-tequip-founder-title fw-bold">
              Prashanth Gurunath
            </span>
            <span className="about-tequip-founder-designation fw-bold">
              Former Director (Nov 2020 - Aug 2021) 
            </span>
            {/* <div className="about_page_social_media_container">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/prashanth-gurunath-2834a6155/"
              >
                <img
                  className="about_page_linkedin_image"
                  src={linkedInImage}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/17_prashanth"
              >
                <img className="about_page_twitter_image" src={twitterImage} />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/prashanthguru.17"
              >
                <img className="about_page_fb_image" src={fbImage} />
              </a>
            </div> */}
          </div>

          {/* <div className="support-team-container">
            <div className="support-team-tequip-text fw-bold">Support Team</div>
            <div className="team-card-list">
              <div className="team-individual-container">
                <div className="avatar">
                  <img
                    src={shivaniPic}
                    alt="..."
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="team-individual-info ml-3">
                  <div className="username">Shivani Sundar</div>
                  <div className="status">Design Lead</div>
                </div>
              </div>
              <div className="team-individual-container">
                <div className="avatar">
                  <img
                    src={rohitthpPrakashPic}
                    alt="..."
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="team-individual-info ml-3">
                  <div className="username">Rohitth Prakash</div>
                  <div className="status">Designer</div>
                </div>
              </div> 
            </div>
          </div> */}
        </section>

        <section
          id="our-values-tequip"
          className="our-values-tequip section section--large section--centered section--in-view"
        >
          <div className="values-tequip-text-container">
            <span className="values-tequip-text fw-bold">OUR VALUES</span>
          </div>

          <div className="values_tequip_container">
            <div className="values_container">
              <img className="values_tequip_image" src={valuesImage1} />
              <span className="values_tequip_image_text">
                We are passionate about the company and it's growth. We take
                pride in our work and we're committed to our clients, our teams,
                and to each other.
              </span>
            </div>

            <div className="values_container">
              <img className="values_tequip_image" src={valuesImage2} />
              <span className="values_tequip_image_text">
                We work enthusiastically as we're hard working individuals that
                reach great heights when we come together with our clients.
              </span>
            </div>

            <div className="values_container">
              <img className="values_tequip_image" src={valuesImage3} />
              <span className="values_tequip_image_text">
                We have a culture of innovation and continuous improvement.
                We're constantly seeking personal and professional growth.
              </span>
            </div>

            <div className="values_container">
              <img className="values_tequip_image" src={valuesImage4} />
              <span className="values_tequip_image_text">
                We elevate our customers through positivity. Our intentions are
                always in the best interests of our customers.
              </span>
            </div>
          </div>
          <footer className="footer-section-about">
            {" "}
            <span>
              Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd. <br />{" "}
              All rights reserved
            </span>{" "}
          </footer>
        </section>
      </div>
    );
  }
}

export default About;
