import React, { Component } from "react";
import "./NewsCard.scss";
import { Redirect } from "react-router";

import yourStory from "../../../assets/publisherImages/ys1.PNG";
import tnie from "../../../assets/publisherImages/tnie.jpg";
import dailyHunt from "../../../assets/publisherImages/dailyhunt.png";
import yahooNews from "../../../assets/publisherImages/yahoonews1.PNG";
import dinamani from "../../../assets/publisherImages/dinamani.jpg";

class NewsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individualnewsDataPageStatus: false,
      loaded: false,
      logo: "",
    };
  }

  componentDidMount = () => {
    let image =
      this.props.newsData.publisherName == "YourStory"
        ? yourStory
        : this.props.newsData.publisherName == "YahooNews"
        ? yahooNews
        : this.props.newsData.publisherName == "Dailyhunt"
        ? dailyHunt
        : this.props.newsData.publisherName == "The New Indian Express"
        ? tnie
        : this.props.newsData.publisherName == "Dinamani"
        ? dinamani
        : "";
    this.setState({
      logo: image,
    });
  };

  renderAvailableNewsCards = () => {
    let result = [];
    if (this.props.newsData != undefined || this.props.newsData != null) {
      result.push(
        <div className="news-card-main-container1">
          <div className="card">
            <div className="p-2">
              <img
                className="card-img-top rounded"
                src={this.state.logo}
                alt="News"
              />
            </div>
            <div className="card-body pt-2">
              <h4 className="mb-1 fw-bold">
                {this.props.newsData.publisherName != null &&
                this.props.newsData.publisherName != undefined
                  ? this.props.newsData.publisherName
                  : "News Card"}
              </h4>
              <h5 className="mb-1 fw-bold">
                {this.props.newsData.articleTitle != null &&
                this.props.newsData.articleTitle != undefined
                  ? this.props.newsData.articleTitle
                  : ""}
              </h5>

              <p className="text-muted small mb-2">
                {this.props.newsData.publishedDate != null &&
                this.props.newsData.publishedDate != undefined
                  ? this.props.newsData.publishedDate
                  : "Date"}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      result.push(<div className="news-card-details"></div>);
    }

    return result;
  };

  openURL = (url) => {
    if (url != null && url != "") {
      window.open(url, "_black");
    }
  };

  render() {
    return (
      <div
        onClick={() => this.openURL(this.props.newsData.articleLink)}
        className="available-news-card shadow"
      >
        {this.renderAvailableNewsCards()}
      </div>
    );
  }
}

export default NewsCard;
