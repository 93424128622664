import React, { Component } from "react";
import "./ProductsCard.scss";
import { Redirect } from "react-router";

import citysquareImage from "../../../assets/productImages/citysquare_logo.png";
import ttdyImage from "../../../assets/productImages/ttdy_cover.jpg";
import cinemaholicappImage from "../../../assets/productImages/cinemaholic_logo.png";
import superchefImage from "../../../assets/productImages/superchef_logo.png";

class ProductsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individualproductDataPageStatus: false,
      loaded: false,
      logo: "",
      gotoIndividualProductPage: false,
      // productData: '',
      url: ''
    };
  }

  componentDidMount = () => {
    let image =
      this.props.productData.key == "citysquareapp"
        ? citysquareImage
        : this.props.productData.key == "cinemaholicapp"
          ? cinemaholicappImage
          : this.props.productData.key == "superchef"
          ? superchefImage
          : this.props.productData.key == "thisdaythatyearapp"
            ? ttdyImage
            : "";
    this.setState({
      logo: image,
    });
  };

  renderAvailableProductsCards = () => {
    let result = [];
    if (this.props.productData != undefined || this.props.productData != null) {
      result.push(
        <div className="products-card-main-container1">
          <div className="card">
            <div className="p-2">
              <img
                className="card-img-top rounded"
                src={this.state.logo}
                alt="app"
              />
            </div>
            <div className="card-body pt-2">
              <h4 className="mb-1 fw-bold">
                {this.props.productData.title != null &&
                  this.props.productData.title != undefined
                  ? this.props.productData.title
                  : "Product Title"}
              </h4>
              <h5 className="mb-1 fw-bold">
                {this.props.productData.shortDes != null &&
                  this.props.productData.shortDes != undefined
                  ? this.props.productData.shortDes
                  : ""}
              </h5>

              <p className="text-muted small mb-2">
                {this.props.productData.publishedDate != null &&
                  this.props.productData.publishedDate != undefined
                  ? this.props.productData.publishedDate
                  : "Date"}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      result.push(<div className="products-card-details"></div>);
    }

    return result;
  };

  openURL = (url) => {

    this.setState({
      gotoIndividualProductPage: true,
      url: url
    })

  };

  render() {

    if (this.state.gotoIndividualProductPage) {

      console.log('productData:: ', this.props.productData)

      return <Redirect to={{
        pathname: '/' + this.state.url,
        state: {
          productData: this.props.productData
        }

      }} />;

    }

    return (

      this.props.productData != undefined && this.props.productData != null ?
        <div
          onClick={() => this.openURL(this.props.productData.key)}
          className="available-products-card shadow"
        >
          {this.renderAvailableProductsCards()}
        </div>
        : <div></div>
    );
  }
}

export default ProductsCard;
