import React, { Component } from "react";
import "./Header.scss";
import tequipLogo from "../../../assets/logo/tequip_logo_with_text_for_darkbg.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleButtonOpen: false,
    };
    this.toggleButtonClicked.bind();
  }

  componentDidMount = () => {
    this.setState({
      toggleButtonOpen: false,
    });
  };

  toggleButtonClicked = () => {
    this.setState((state) => ({
      toggleButtonOpen: !state.toggleButtonOpen,
    }));
  };

  render() {
    return (
      <div className="tequip-header-container">
        {/* <div className="card header-main-container">
  <div className="header-sub-container">
    <div className="website-title-container">
      <img className="tequip-logo" />
      <span className="website-title" href="#">TeQuip</span>
    </div>
    <span className="header">
      <span className="header-text"><a href="/">Home</a></span>
      <span className="header-text"><a href="about">About</a></span>
      <span className="header-text"><a href="products">Products</a></span>
      <span className="header-text"><a href="in-the-news">In The News</a></span>
    </span>
  </div>
</div> */}

        <button
          onClick={this.toggleButtonClicked}
          className="toggle | header__toggle"
          aria-label="Toggle navigation"
        >
          <span className="is-hidden">TeQuip</span>
          <span
            className={
              this.state.toggleButtonOpen
                ? "toggle__icon | icon icon--menu-close"
                : "toggle__icon | icon icon--menu-open"
            }
          ></span>
        </button>

        <img
          className={
            this.state.toggleButtonOpen
              ? "tequip-logo-header-open"
              : "tequip-logo-header-open"
          }
          onClick={(event) => (window.location.href = "/")}
          src={tequipLogo}
        />

        <div
          className={
            !this.state.toggleButtonOpen
              ? "is-active-nav nav-container header__nav"
              : "is-active-nav nav-container header__nav_open"
          }
          id="navigation"
        >
          <div className="nav nav--invert">
            <span className="side_nav_item">
              <a href="/" className="header_nav_link">
                Home
              </a>
            </span>
            <span className="side_nav_item">
              <a href="/about" aria-current="page" className="header_nav_link">
                About
              </a>
            </span>
            <span className="side_nav_item">
              <a href="/products" className="header_nav_link">
                Products
              </a>
            </span>
            <span className="side_nav_item">
              <a href="/in-the-news" className="header_nav_link">
                In The News
              </a>
            </span>
            <span className="side_nav_item">
              <a href="/careers" className="header_nav_link">
                Careers
              </a>
            </span>
            <span className="side_nav_item">
              <a href="/contact" className="header_nav_link">
                Contact
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
