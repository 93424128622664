import React, { Component } from "react";
import "./Splash.scss";
import logo from "../../../assets/logo/tequip_logo_with_text_for_lightbg.png";

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <div className="tequip-splash-container">
        <img
          alt="Tequip"
          className="tequip-splash-icon"
          src={logo}
          alt={logo}
        />
      </div>
    );
  }
}

export default Splash;
