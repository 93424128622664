import React, { Component } from "react";

import "./Contact.scss";
import Header from "../../components/Header/Header";
import contactPageIllstn from "../../../assets/contact_us.png";
import linkedInImage from "../../../assets/socialMedia/linkedin.png";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="contact-main-container">
        <Header />

        <section
          id="contact-tequip"
          className="section section--large section--centered section--in-view"
        >
          <div className="contact-tequip-container">
            <span className="contact-tequip-text fw-bold">CONTACT US</span>
          </div>
          <div className="contact_tequip_text_container">
            <img className="contact-us-illstn" src={contactPageIllstn} />

            <span className="contact-tequip-text fw-bold">
              How can we help?
            </span>

            <span className="contact-tequip-text-desc">
              Allow us to build your company's website, app, brand. Please get
              in touch with us by filling this google form and our expert
              support team will get back to you.
            </span>
            <a
              href="https://forms.gle/s7yx71boAaaeZv1G6"
              className="btn-contact-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get in touch
            </a>
            <span className="contact-email-text">
              You can also reach out to us at: <br />
              gurunath.neelamani@gmail.com
              
            </span>
            <span className="contact-tequip-support-social-media-container">
              Follow us on LinkedIn
            </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://in.linkedin.com/company/tequip-software-solutions?trk=public_profile_topcard-current-company"
            >
              <img
                className="contact_page_linkedin_image"
                src={linkedInImage}
              />
            </a>
          </div>
          <footer className="footer-section-products">
            {" "}
            <span>
              Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd. <br />{" "}
              All rights reserved
            </span>{" "}
          </footer>
        </section>
      </div>
    );
  }
}

export default Contact;
