import React, { Component } from "react";

import "./InTheNews.scss";
import NewsCard from "./../../components/NewsCard/NewsCard";

// logo

import yourStory from "../../../assets/publisherImages/ys1.PNG";
import tnie from "../../../assets/publisherImages/tnie.jpg";
import dailyHunt from "../../../assets/publisherImages/dailyhunt.png";
import yahooNews from "../../../assets/publisherImages/yahoonews1.PNG";
import dinamani from "../../../assets/publisherImages/dinamani.jpg";
import Header from "../../components/Header/Header";

class InTheNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsData: [],
    };
  }

  componentDidMount() {
    let sampleNewsData = [];
    sampleNewsData.push(
      {
        publisherLogo: { tnie },
        publisherName: "The New Indian Express",
        publishedDate: "June 11th, 2020",
        articleTitle:
          "Meeting friends made ‘app’solutely easy. It saves fuel, money and time, explains the 22-year-old. ",
        articleLink:
          "https://www.newindianexpress.com/cities/chennai/2020/jun/11/meeting-friends-made-appsolutely-easy-2154905.html",
      },
      {
        publisherLogo: { yourStory },
        publisherName: "YourStory",
        publishedDate: "September 13th, 2020",
        articleTitle:
          "This app aims to make meetups easy and convenient by finding equidistant locations",
        articleLink:
          "https://yourstory.com/2020/09/city-square-app-central-location-meetings",
      },

      {
        publisherLogo: { yahooNews },
        publisherName: "YahooNews",
        publishedDate: "September 13th, 2020",
        articleTitle:
          "This app aims to make meetups easy and convenient by finding equidistant locations",
        articleLink:
          "https://in.news.yahoo.com/app-aims-meetups-easy-convenient-000500926.html?guce_referrer=ahr0chm6ly93d3cuz29vz2xllmnvbs8&guce_referrer_sig=aqaaajcgtj_cb_7mciliqsdbuzwpmz4dzy_dh5ofxpfgnlvdksgeblfphqpxdaruqincfhcsfmjnct0xnnlgpc_izn3wziy0quuq_o5idbqpawvch1sqs4jluajdc3fv_evmf7c7-5iswzylvtrs_s0yh7vfar9al6q-yof-sewfran_",
      },
      {
        publisherLogo: { dailyHunt },
        publisherName: "Dailyhunt",
        publishedDate: "September 13th, 2020",
        articleTitle:
          "This app aims to make meetups easy and convenient by finding equidistant locations",
        articleLink:
          "https://m.dailyhunt.in/news/india/english/yourstory-epaper-yourstory/this+app+aims+to+make+meetups+easy+and+convenient+by+finding+equidistant+locations-newsid-n214263698",
      },
      {
        publisherLogo: { yourStory },
        publisherName: "YourStory",
        publishedDate: "October 24th, 2020",
        articleTitle:
          "நண்பர்களை சந்திக்க பொது இடங்களை பரிந்துரைக்கும் ஆப் நிறுவிய சென்னை இளைஞர்",
        articleLink:
          "https://yourstory.com/tamil/city-square-app-central-location-meetings",
      },
      {
        publisherLogo: { dinamani },
        publisherName: "Dinamani",
        publishedDate: "November 8th, 2020",
        articleTitle: "எல்லாருக்கும் பொது இடம்... கண்டுபிடிக்கும் செயலி!",
        articleLink:
          "https://www.dinamani.com/weekly-supplements/ilaignarmani/2020/nov/10/public-space-for-everyone-discovery-processor-3500946.html",
      }
    );
    this.setState({
      newsData: sampleNewsData,
    });
  }

  render() {
    return (
      <div className="in-the-news-main-container">
        <Header />

        <div className="news_platform_text_container">
          <span className="news_platform_text fw-bold">
            Our success story has been featured in the following platforms
          </span>
        </div>

        <div className="in-the-news-news-card-container">
          {this.state.newsData.map((el, index) => (
            <NewsCard
              key={index}
              newsData={this.state.newsData[index]}
              id={index}
            />
          ))}
        </div>
        <footer className="footer-section-products">
          <span>
            Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd. <br />
            All rights reserved
          </span>
        </footer>
      </div>
    );
  }
}

export default InTheNews;
