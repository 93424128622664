import React, { Component } from "react";

import "./CinemaholicProduct.scss";
import Header from "../../components/Header/Header";
import googlePlayIcon from "../../../assets/google-play-badge.png";
import linkedInImage from "../../../assets/socialMedia/linkedin.png";


class CinemaholicProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indProductDetails: ''
        };
    }

    componentDidMount() {

        console.log('this.props.productData:: ', this.props.location.state.productData)

        if (this.props.location.state.productData != null && this.props.location.state.productData != undefined) {
            this.setState({ indProductDetails: this.props.location.state.productData })
        }
    }

    render() {
        return (
            <div className="products-main-container">
                <Header />

                <section
                    id="our-products-tequip"
                    className="section section--large section--centered section--in-view"
                >

                    <div className="city_square_app_container">
                        {/* <img className="city_square_illstn" src={citySquareIllstn} /> */}
                        <div className="city_square_app_desc_container">
                            <span className="city_square_app_desc_title fw-bold">
                                {this.state.indProductDetails.title} App
                            </span>
                            <span className="city_square_app_desc_text fw-bold">
                                {this.state.indProductDetails.shortDes}
                                {this.state.indProductDetails.longDes}
                            </span>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.tequip.cinemaholic"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img className="google_play_badge" src={googlePlayIcon} />
                            </a>
                        </div>
                    </div>

                    <div className="city-square-problemstatement-container">
                        <span className="city_square_app_desc_title fw-bold">
                            MORE ABOUT CINEMAHOLIC APP
                        </span>
                        <span className="city_square_app_desc_text fw-bold">
                        {this.state.indProductDetails.des1}

                        </span>
                    </div>
                    <div className="city_square_stats_container">
                        <div className="city_square_stats_sub_container">
                            <div className="city_square_downloads_container">
                                <span className="city_square_stat_heading fw-bold">500+</span>
                                <span className="city_square_stat_content fw-bold">
                                    Downloads
                                </span>
                            </div>
                            <div className="city_square_downloads_container">
                                <span className="city_square_stat_heading fw-bold">4.9</span>
                                <span className="city_square_stat_content fw-bold">Rating</span>
                            </div>
                            <div className="city_square_downloads_container">
                                <span className="city_square_stat_heading fw-bold">30+</span>
                                <span className="city_square_stat_content fw-bold">
                                    Reviews
                                </span>
                            </div>
                        </div>

                        {/* <iframe
                            className="city_square_video"
                            src="https://www.youtube.com/embed/N8HtO0fh4z8"
                        /> */}
                    </div>
                </section>

                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/prashanth-gurunath-2834a6155/"
                >
                    <img
                        className="about_page_linkedin_image"
                        src={linkedInImage}
                    />
                </a>
                <footer className="footer-section-products">
                    <span>
                        Copyright 2021 &copy; TeQuip Software Solutions Pvt Ltd. <br />
                        All rights reserved
                    </span>
                </footer>
            </div>
        );
    }
}

export default CinemaholicProduct;
